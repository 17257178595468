import axiosInstance from '../configs/axios';
import { BASE_URL, ROUTES, API, IS_CLIENT } from '../constants';

class RequestClass {
  constructor() {
    this.signInInitUrl = API.SIGN_IN_INIT;
    this.signInFinalUrl = API.SIGN_IN_FINAL;
    this.autologinUrl = API.AUTO_LOGIN;
    this.forgotUsernameUrl = API.FORGOT_USERNAME;
    this.forgotPassUrl = API.FORGOT_PASS;
    this.resetPassUrl = API.RESET_PASS;
    this.getMaintenanceUrl = API.GET_MAINTENANCE;
  }

  async signIn(data) {
    try {
      const axiosResponse = await axiosInstance.post(this.signInInitUrl, data);
      const { data: response } = axiosResponse;
      const { data: responseData } = response;
      const { redirectionToken, redirectionUrl, wrongDomain } = { ...responseData };

      if (wrongDomain && IS_CLIENT) {
        window.location.href = `https://${redirectionUrl}/?token=${redirectionToken}`;
        return false;
      }

      return await this.signInFinal(redirectionToken);
    } catch (error) {
      return { errorMessage: error.message || 'Unexpected error. Please try again.' };
    }
  }

  async signInFinal(redirectionToken) {
    try {
      axiosInstance.axiosClient.head();

      const axiosResponse = await axiosInstance.get(
        `${this.signInFinalUrl}/?devMode=${window.location.hostname === 'localhost'}`,
        redirectionToken
      );

      const { data: response } = axiosResponse;
      const { data: responseData } = response;
      const { autologinUrl, isNewUser, accessToken, refreshToken } = { ...responseData };

      await axiosInstance.setTokens(accessToken, refreshToken);
      return { autologinUrl, isNewUser };
    } catch (error) {
      return { errorMessage: error.message || 'Unexpected error. Please try again.' };
    }
  }

  async autologin() {
    try {
      const response = await axiosInstance.get(this.autologinUrl);

      if (response.status === 200 && response.data.autologinUrl) {
        return { autologinUrl: response.data.autologinUrl };
      }

      if (response.error) {
        return { errorMessage: `${response.error}: ${response.message}` };
      }
    } catch (error) {
      if (error.statusCode === 401 && error.message === 'Unauthorized') {
        if (IS_CLIENT) {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
        }
      }
      return { errorMessage: error.message };
    }

    return { errorMessage: 'Unexpected error. Please try again.' };
  }

  async forgotUsername(email) {
    try {
      const response = await axiosInstance.post(this.forgotUsernameUrl, { email });

      if (response.status === 200) {
        return response.data;
      }

      if (response.error) {
        return { errorMessage: `${response.error}: ${response.message}` };
      }
    } catch (error) {
      return { errorMessage: error.message };
    }
    return { errorMessage: 'Unexpected error. Please try again.' };
  }

  async forgotPass(email) {
    try {
      const response = await axiosInstance.post(this.forgotPassUrl, {
        email,
        url: `${BASE_URL}${ROUTES.RESET_PASS}`,
      });

      if (response.status === 200) {
        return response.data;
      }

      if (response.error) {
        return { errorMessage: `${response.error}: ${response.message}` };
      }
    } catch (error) {
      return { errorMessage: error.message };
    }
    return { errorMessage: 'Unexpected error. Please try again.' };
  }

  async resetPass(data) {
    try {
      const response = await axiosInstance.post(this.resetPassUrl, data);

      if (response.status === 200) {
        return response.data;
      }

      if (response.error) {
        return { errorMessage: response.message };
      }
    } catch (error) {
      return { errorMessage: error.message };
    }
    return { errorMessage: 'Unexpected error. Please try again.' };
  }

  async getMaintenance() {
    try {
      const response = await axiosInstance.get(this.getMaintenanceUrl);

      if (response.status === 200) {
        return response.data;
      }

      if (response.error) {
        return { errorMessage: response.message };
      }
    } catch (error) {
      return { errorMessage: error.message };
    }
    return { errorMessage: 'Unexpected error. Please try again.' };
  }
}

export default new RequestClass();
